$(document).ready(function() {
	var playerIdle = null;

	$('.home-section .tile').click(function(){
		$("#mainModal").modal('show');
	});

	if ($('.home-section article').length > 0) {
		// normalizeDivArea($('.home-section .article-content'));

		$(window).resize(function() {
			// normalizeDivArea($('.home-section .article-content'));
		});
	}

	//---- bg particles
	// if($("#particles-js").length > 0) {
	// 	initParticles();
	// }

	// SCROLL MAGIC ANIMATiONS
	var smController = new ScrollMagic.Controller();

	//--- onview animation
	// $('.home-section .section-container').each(function(){
	// 	var scene = new ScrollMagic.Scene({
	// 		triggerElement: this,
	//         triggerHook: 0.5,
	//         reverse: true
	// 	})
	// 	.addIndicators()
	// 	.setClassToggle(this, "in")
	// 	.addTo(smController);	
	// });

	// NAVBAR SCROLL ANIMATION
	new ScrollMagic.Scene({
			triggerElement: 'body',
	        triggerHook: 0,
	        offset: $("#mainNavbar").offset().top
		})
		// .addIndicators()
		.setClassToggle('#mainNavbar', "moved")
		.addTo(smController);

	new ScrollMagic.Scene({
			triggerElement: 'body',
	        triggerHook: 0,
	        offset: $(".header").outerHeight() - ($("#mainNavbar").outerHeight() / 2)
		})
		// .addIndicators()
		.setClassToggle('#mainNavbar', "overlayed")
		.addTo(smController);

	//--- side controls bg color change 
	$(".page-control").each(function() {
		new ScrollMagic.Scene({
			triggerElement: 'body',
	        triggerHook: (($(this).offset().top + $(this).height()) / $(window).height()),
	        offset: $(".header").outerHeight() + ($(this).outerHeight() / 2)
		})
		// .addIndicators()
		.setClassToggle(this, "overlayed")
		.addTo(smController);
	});

	//Parallax
	$('.home-section article').each(function(){
		if (!$(this).parents('.section-container').hasClass('side-by-side')) {
			if ($(this).hasClass('front-article')) {
				var timeline = new TimelineMax();
				var tween1 = TweenMax.from(this, 1, {y: '100%', ease: Power0.easeNone});
				var tween2 = TweenMax.to(this, 1, {y: '-50%', ease: Power0.easeNone});
				timeline
				        .add(tween1)
				        .add(tween2);
			
			var parallax = new ScrollMagic.Scene({
				triggerElement: this.parentElement,
		        triggerHook: 1,
		        duration: '150%'
			})
			// .addIndicators()
			.setTween(timeline)
			.addTo(smController);	
		} else if ($(this).hasClass('back-article')) {
			var timeline = new TimelineMax();
			var tween1 = TweenMax.from(this, 1, {y: '50%', ease: Power0.easeNone});
			var tween2 = TweenMax.to(this, 1, {y: '0%', ease: Power0.easeNone});
			timeline
			        .add(tween1)
			        .add(tween2);
			var parallax = new ScrollMagic.Scene({
				triggerElement: this.parentElement,
		        triggerHook: 1,
		        duration: '150%'
			})
			// .addIndicators()
			.setTween(timeline)
			.addTo(smController);
		}
		}
		
	});

	//STRIPS ANIMATION
	// $('.article-bg-parallax .strip').each(function() {
	// 	var tween;
		
	// 	if($(this).parents('.section-container').hasClass('right')) {
	// 		tween = TweenMax.from(this, 1, {x: '100%', ease: Power0.easeNone});
	// 	} else if($(this).parents('.section-container').hasClass('left')) {
	// 		tween = TweenMax.from(this, 1, {x: '-100%', ease: Power0.easeNone});
	// 	}
	// 	var parallax = new ScrollMagic.Scene({
	// 		triggerElement: this,
	//         triggerHook: 1,
	//         duration: '100%'
	// 	})
	// 	// .addIndicators()
	// 	.setTween(tween)
	// 	.addTo(smController);
	// });

	// $(".section-container .after").each(function() {
	// 	var tween = TweenMax.to(this, 1, {y: '50%', ease: Power0.easeNone});
	// 	var duration = "100%";
	// 	if($(this).parent().hasClass('side-by-side')) {
	// 		tween = TweenMax.from(this, 1, {y: '-50%', ease: Power0.easeNone});
	// 		duration = "50%";
	// 	}

	// 	var parallax = new ScrollMagic.Scene({
	// 			triggerElement: this.parentElement,
	// 	        triggerHook: 1,
	// 	        duration: duration
	// 		})
	// 		// .addIndicators()
	// 		.setTween(tween)
	// 		// .setClassToggle($(this), "in")
	// 		.addTo(smController);
	// });

	//---- fold in-view animation
	$(".folded.in-view").each(function() {
		var reversible = false;

		if($(this).attr('reversible') === 'true')
			reversible = true;

		var inView = new ScrollMagic.Scene({
						triggerElement: this,
				        triggerHook: 0.75,
				        reverse: reversible
					})
					// .addIndicators()
					.setClassToggle($(this), "unfolded")
					.addTo(smController);

	});

	//---- fade in-view animation
	$(".fade-in.in-view").each(function() {
		var reversible = false;

		if($(this).attr('reversible') === 'true')
			reversible = true;

		var inView = new ScrollMagic.Scene({
						triggerElement: this,
				        triggerHook: 0.75,
				        reverse: reversible
					})
					// .addIndicators()
					.setClassToggle($(this), "in")
					.addTo(smController);

	});

	//---- pin parallax
	$(".pin-in-position").each(function() {

		var pin = new ScrollMagic.Scene({
						triggerElement: $(this).parent(),
				        triggerHook: 0,
				        duration: '200%'
					})
					// .addIndicators()
					.setTween(TweenMax.to(this, 1, {y: '100%', ease: Power0.easeNone}))
					.addTo(smController);

	});
	
	// FOOTER LOGO PARALLAX
	var footerLogo = new ScrollMagic.Scene({
			triggerElement: '.footer-content',
	        triggerHook: 1,
	        duration: $('.footer-content').height()
		})
		// .addIndicators()
		.setTween(TweenMax.from('.footer-logo', 1, {y: '-100%', ease: Power0.easeNone}))
		.addTo(smController);

	bannerSlider = $('.banner-slider').slider({
		duration: 40000,
		arrows: true,
		nextCallback: slideChangeCallback,
		prevCallback: slideChangeCallback
	});

	$(".hero-banner .vid-control-btn")
		.click(function(e) {
			e.stopPropagation();
			e.preventDefault();
			
			var videoContainer = $(this).parents('.video-banner');
			var videoId = videoContainer.find('iframe').attr('id');
			var action = $(this).data("action");

			if(action === "play") {
				if(playVideo(videoId)) {
					videoContainer.addClass("playing played");	
					playerIdle = setTimeout(function() {
						videoContainer.addClass("idle");
					}, 4000);
				}
			} else {
				clearTimeout(playerIdle);
				videoContainer.removeClass("playing idle");
				pauseVideo(videoId);
			}
			
			$(this).addClass("active");
			$(this).siblings(".vid-control-btn").removeClass("active");
		})
		.mousedown(function(e) {
			e.stopPropagation();
			e.preventDefault();
		});

	$(document).on("mousemove", ".video-banner", function() {
		var _this = $(this);
		if(_this.hasClass("playing")) {
			_this.removeClass("idle");
			clearTimeout(playerIdle);
			playerIdle = setTimeout(function() {
							_this.addClass("idle");
						}, 4000);
		}
	});

	var pageOptions = {
		menu: 0,
		sound: 0,
		scroll: 0
	};

	$(".page-control").click(function() {
		var _this = $(this);
		var action = _this.data("action");

		pageOptions[action] = pageOptions[action] ? 0 : 1;
		if(action === 'menu') {
			toggleMenu(pageOptions[action]);

			if(pageOptions[action]) {
				_this.find('.custom-burger').addClass('active');
			} else {
				_this.find('.custom-burger').removeClass('active');
			}
		} else if (action === 'sound') {
			togglePageSound(pageOptions[action]);

			if(pageOptions[action]) {
				_this.addClass('active');
			} else {
				_this.removeClass('active');
			}
		}
	});

	if ($(window).width() <= 991) {
		$(".grayscale.disable-gray-in-view").each(function() {
			new ScrollMagic.Scene({
					triggerElement: this,
			        triggerHook: 0.5
				})
				// .addIndicators()
				.setClassToggle($(this), "disabled")
				.addTo(smController);
		});
	}

	//---remove grayscale on grayscaled elements inside an article
	$(".article-container").mouseenter(function() {
		$(this).find('.grayscale').addClass('disabled');
	});

	function toggleMenu(state) {
		if(state) {
			$("#mainNavbar .links").addClass('in');
		} else {
			$("#mainNavbar .links").removeClass('in');
		}
	}

	function togglePageSound(state) {
		if(!state) {
			$.each(videos, function() {
				this.unMute();
			});	
		} else {
			$.each(videos, function() {
				this.mute();
			});	
		}
	}

	function playVideo(id) {
		if(!bannerSlider.inTransition() && videos[id] !== undefined) {
			try {
				videos[id].playVideo();
				bannerSlider.stop();
				return true;	
			} catch(err) {
				return false;
			}
		} else {
			return false;
		}
	}

	function pauseVideo(id) {
		videos[id].pauseVideo();
		bannerSlider.play();
	}

	function slideChangeCallback () {
		var slide = $(".slide.active");
		if(slide.find('.video-banner').length > 0) {
			slide.find('.pause-btn').click();
		}
	}
});

var bannerSlider = null;

//---- load YOUTUBE VIDEOS
var videos = {};
function onYouTubeIframeAPIReady() {
	$('.video-iframe').each(function() {
		var id = $(this).attr('id');

		if(id) {
			videos[id] = getYTVideo(id);
			// console.log(videos[id]);
		}
	});
}
//---- get and create iframe for youtube video
function getYTVideo(id) {
	var player;
	player = new YT.Player(id, {
      height: '1080',
      width: '1920',
      videoId: id,
      playerVars: {
      	modestbranding: 1,
      	controls: 0,
      	showinfo: 0,
      	rel: 0,
      	autoplay: 0,
      	loop: 0
      },
      events: {
        'onStateChange': onVideoStateChange
      }
    });

    return player;
}

function onVideoStateChange(e) {
	//---- video ended
	if(e.data === 0) {
		videoStopped(e.target.a.id);
	}
}

function videoStopped(videoId) {
	var videoContainer = $("#" + videoId).parents('.video-banner');
	videoContainer.removeClass("playing played idle");
	bannerSlider.play();
}

function normalizeDivArea($el) {
	var height = 0;
	
	$.each($el, function() {
		height = $(this).outerHeight() > height ? $(this).outerHeight() : height;
	});

	$el.css("height", height + "px");
	$('.article-container article').css("height", height + "px");
}

function initParticles() {
	particlesJS("particles-js", {
	  "particles": {
	    "number": {
	      "value": 33,
	      "density": {
	        "enable": true,
	        "value_area": 1420.4657549380909
	      }
	    },
	    "color": {
	      "value": "#000000"
	    },
	    "shape": {
	      "type": "triangle",
	      "stroke": {
	        "width": 0,
	        "color": "#000000"
	      },
	      "polygon": {
	        "nb_sides": 5
	      },
	      "image": {
	        "src": "img/github.svg",
	        "width": 100,
	        "height": 100
	      }
	    },
	    "opacity": {
	      "value": 0.2,
	      "random": true,
	      "anim": {
	        "enable": false,
	        "speed": 1,
	        "opacity_min": 0.1,
	        "sync": false
	      }
	    },
	    "size": {
	      "value": 11.83721462448409,
	      "random": true,
	      "anim": {
	        "enable": false,
	        "speed": 40,
	        "size_min": 3,
	        "sync": false
	      }
	    },
	    "line_linked": {
	      "enable": true,
	      "distance": 150,
	      "color": "#000000",
	      "opacity": 0.4,
	      "width": 1
	    },
	    "move": {
	      "enable": true,
	      "speed": 2,
	      "direction": "none",
	      "random": false,
	      "straight": false,
	      "out_mode": "out",
	      "bounce": false,
	      "attract": {
	        "enable": false,
	        "rotateX": 600,
	        "rotateY": 1200
	      }
	    }
	  },
	  "interactivity": {
	    "detect_on": "canvas",
	    "events": {
	      "onhover": {
	        "enable": false,
	        "mode": "repulse"
	      },
	      "onclick": {
	        "enable": true,
	        "mode": "repulse"
	      },
	      "resize": true
	    },
	    "modes": {
	      "grab": {
	        "distance": 400,
	        "line_linked": {
	          "opacity": 1
	        }
	      },
	      "bubble": {
	        "distance": 400,
	        "size": 40,
	        "duration": 2,
	        "opacity": 8,
	        "speed": 3
	      },
	      "repulse": {
	        "distance": 200,
	        "duration": 0.4
	      },
	      "push": {
	        "particles_nb": 4
	      },
	      "remove": {
	        "particles_nb": 2
	      }
	    }
	  },
	  "retina_detect": true
	});
	 
}